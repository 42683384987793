import './index.scss'

$('footer .footer-nav ul li .title .iconfont').on('click',function(){
  let $parent = $(this).closest('li')
  if($parent.hasClass('active')){
    $parent.removeClass('active').find('.sub-nav').slideUp()
  }else{
    $parent.siblings().removeClass('active').find('.sub-nav').slideUp()
    $parent.addClass('active').find('.sub-nav').slideDown()
  }
})