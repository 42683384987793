module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
if (!data) var data = [];
;
__p += '\r\n<section class="ny-banner">\r\n  <div class="img bg-cover" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></div>\r\n  <div class="cont container">\r\n    <h1>' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\r\n    <div class="crumbs">\r\n      <a href="#">首页</a>\r\n      ';
 for (var i = 0; i < data.length; i++) { ;
__p += '\r\n        <span>&gt;</span><a href="#">' +
((__t = ( data[ i ] )) == null ? '' : __t) +
'</a>\r\n      ';
 } ;
__p += '\r\n    </div>\r\n  </div>\r\n</section>\r\n';

}
return __p
}