import './index.scss'
// !(function(){
//   location.href="http://www.qdhhnotary.com/";
// })()

$('[data-open="cmenu"]').on('click', function () {
  if($('header .hamburger').hasClass('active')){
    $('header').removeClass('show-menu')
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
    $('body').css({
      'height':'auto',
      'overflow-y':'auto'
    })
  }else{
    $('header').addClass('show-menu')
    $('header .hamburger').addClass('active')
    $('header .mob-header').addClass('show')
    $('header .menu-mask').fadeIn(200)
    $('body').css({
      'height':'100vh',
      'overflow-y':'hidden'
    })
  }
})

$('.mob-header ul li .sub-nav a').on('click',function(){
  setTimeout(function(){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
  },200)
})

$('.mob-header ul li .title .iconfont').on('click',function(){
  let $parentEl = $(this).parents('li')
  if($parentEl.hasClass('active')){
    $parentEl.removeClass('active')
    $parentEl.find('.sub-nav').slideUp()
  }else{
    $parentEl.addClass('active').siblings().removeClass('active')
    $parentEl.find('.sub-nav').slideDown()
    $parentEl.siblings().find('.sub-nav').slideUp()
  }
})

$('#header-search-open').on('click',function(){
  $('.header-search').addClass('active')
  $('#header-search-input').focus()
})
$('.header-search .header-search-close').on('click',function(){
  $('.header-search').removeClass('active')
  $('#header-search-input').blur()
})

$(document).ready(function(){
  $('.mob-header ul li').each(function(index){
    if($(this).find('.sub-nav a').length > 0){
      $(this).find('.title .iconfont').css('display','flex')
    }
  })
  if($(window).width() < 768){
    $('.footer-nav ul li').each(function(index){
      console.log($(this).find('.sub-nav a').length)
      if($(this).find('.sub-nav a').length > 0){
        $(this).find('.title .iconfont').css('display','flex')
      }
    })
  }else{
    $('.footer-nav ul li .title .iconfont').css('display','none')
  }
})

$(window).resize(function(){
  if($(window).width() < 768){
    $('.footer-nav ul li').each(function(index){
      if($(this).find('.sub-nav a').length > 0){
        $(this).find('.title .iconfont').css('display','flex')
      }
    })
  }else{
    $('.footer-nav ul li .title .iconfont').css('display','none')
  }
})

let beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度
  var headerTop = $('.header-top').outerHeight()

  if(scroH > headerTop){
    $('header').addClass('scroll')
  }else{
    $('header').removeClass('scroll')
  }

  //判断页面是上滚还是下滚
  let delta = scroH - beforeScroH;
  if (delta > 0) {
    if(scroH > 200){
      $('header').addClass('trans')
    }else{
      $('header').removeClass('trans')
    }
  }
  if (delta < 0) {
    $('header').removeClass('trans')
  }

  beforeScroH = scroH;
});